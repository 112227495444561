@import '../../../helpers/styles/mixins.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .spinnerWrapper {
    height: 100%;
    display: flex;
    align-items: center; }

  .login {
    display: flex;
    width: 100%;
    height: 100%;
    background: var(--primary-color) url('../../../helpers/images/LoginImage.jpg') center/cover no-repeat;

     :global {
      a.ant-btn {
        padding-top: 6.4px !important; } } }

  .trackerLogo {
    position: absolute;
    top: 32px;
    left: 32px; }

  .loginWrapper {
    overflow: auto;
    position: relative;
    padding: 32px 0 32px 32px;
    width: 620px;
    height: 100%;
    background-color: #fff; }

  .authMessage {
    display: inline-block;
    color: red;
    font-weight: bold;
    max-width: 300px; }

  .loginPageBody {
    display: flex;
    flex-direction: column;
    gap: 45px;
    padding-left: 100px;
    padding-top: 142px;
    width: 100%;

    h1 {
      margin: 0;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 47px;
      color: var(--font-black); }

    p {
      margin: 0;
      font-family: Gilroy;
      color: rgba(#000000, 0.5);
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px; } }

  .loginPageButton {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    line-height: 38px;
    font-family: Roboto;
    font-weight: 500;
    border: 1px solid #DADADA;
    border-radius: 5px;
    color: var(--black-constant);

    &:hover,
    &:focus {
      border: 1px solid #DADADA;
      border-radius: 5px; }

    .googleLogo {
      margin-right: 10px; }

    .msLogo {
      margin-right: 14px; }

     :global {
      .ant-btn-loading-icon {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
        width: 25px;
        height: 26px; } } }

  .loginPageFooter {
    padding-top: 25px;
    width: 100%;
    border-top: 1px solid var(--button-hover-gray);

    a {
      display: inline-block;
      width: 135px;
      height: 40px; }

    .google-play,
    .google-store-logo,
    .apple-store {
      cursor: pointer; }

    .google-store-logo {
      display: inline-block;
      width: 135px;
      height: 40px; } }

  @include mq('tablet-wide') {
    .loginPageBody {
      display: flex;
      flex-direction: column;
      padding-left: 0;
      gap: 45px;
      width: auto;
      height: 100%;
      padding-top: 25vh;

      h1 {
        max-width: 200px; } }

    .loginPageBodyWelcome {
      flex-grow: 2; }

    .trackerLogo {
      top: 24px;
      left: 50%;
      transform: translate(-50%); }

    .loginWrapper {
      padding: 32px 32px 30px 32px;
      display: flex;
      align-items: center; } } }

body[data-theme='dark'] {
  .loginPageBody {
    p {
      color: rgba(white, 0.5); } }

  .loginPageButton {
    background-color: #fff;

    &:active,
    &:hover,
    &:focus {
      background-color: #fff; } }

  .loginWrapper {
    background-color: var(--primary-backround-black); }

  .loginPageFooter {
    border-top: 1px solid var(--dt-qs-gray-20); } }




