$popoverBackgroundColor: #222222;

body[data-theme='light'],
body[data-theme='dark'] {
  .tooltip {
    &_inner {
      background-color: $popoverBackgroundColor;
      border-radius: 10px;
      display: flex;
      flex-direction: column; }

    &_tongue {
      position: absolute;
      height: 9px;
      fill: $popoverBackgroundColor;
      transform: translate(-10px, -50%) rotate(90deg);
      top: 50%;
      left: 0; }

    &_overlay {
       :global {
        .ant-tooltip-inner {
          padding: 12px 20px;
          border-radius: 10px;
          background-color: #222222; }

        .ant-tooltip-arrow {
          display: none; } } } }

  .activityDrawer {
    height: 100%;

    .drawerHeader {
      width: 100%;
      padding-bottom: 16px;
      border-bottom: 1px solid #E8EDF1;
      padding-right: 8px;

      .partnerMaskWrapper {
        width: 90px;
        height: 90px;
        border-radius: 50% !important;

        .partnerMask {
          width: 60px !important;
          height: 60px !important; } }


      .headerName {
        font-family: Gilroy;
        font-size: 25px;
        line-height: 29px;
        color: var(--font-black); }

      .headerIdentifier {
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgba(#727981, 0.75);

        .title-splitter {
          padding: 0 4px; } } }

    .drawerBody {
      width: 100%;
      padding-top: 32px;
      font-size: 16px;

      .descriptionWrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1; }

      .author {
        display: flex;
        align-items: center; }

      .row {
        display: flex;
        width: 100%;
        padding: 20px 25px 23px 0;
        border-bottom: 1px solid #E8EDF1;

        .rowName {
          flex: 140px 0 0;
          text-align: left;
          font-size: 15px;
          color: var(--qs-gray); }

        .pair {
          display: flex;
          flex-direction: row;
          flex-grow: 1;

          .info {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .title,
            .text {
              font-size: 15px; }

            .title {
              color: var(--qs-gray); } } } } } }

  .activityDrawerFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 14px; } }

body[data-theme='dark'] {
  .activityDrawer {
    .drawerHeader {
      border-bottom: 1px solid var(--border-gray-30);
      .headerIdentifier {
        color: var(--dt-qs-gray); } }
    .drawerBody {
      color: white;

      .row {
        border-bottom: 1px solid var(--border-gray-30); } } } }

