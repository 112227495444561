$LIST_ITEM_WIDTH: 46px;
$MENU_HEIGHT: 64px;

body[data-theme='light'],
body[data-theme='dark'] {
  .mobileTimeSheet {
    width: 100%;
    height: calc(100vh - #{$MENU_HEIGHT} - (100vh - 100%));
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-top: 10px;
    -moz-user-select: none;
    user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none; }

  .monthWrapper {
    flex: 2 2 60%;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      z-index: 1;
      pointer-events: none; }

    &:after {
      right: 0;
      background: linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); }

    &:before {
      left: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); } }

  .month {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 100vw;
    overflow-x: scroll;
    padding-bottom: 10px;

    -ms-overflow-style: none;  // IE and Edge
    scrollbar-width: none;  // Firefox

    &::-webkit-scrollbar {
      display: none; } }


  .loadingWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .25s ease;
    background-color: rgba(255, 255, 255, .75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; }

  .emptySpaceBlock {
    flex: 0 0 calc(100vw - #{$LIST_ITEM_WIDTH});
    height: 100%; } }

body[data-theme='dark'] {

  .mobileTimeSheet {
    background-color: var(--primary-backround-black); } }
